<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10561_361505)">
      <path
        d="M19 19L11.97 15.5L5 19V3H19V19Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path d="M9 7H15" stroke-width="1.5" stroke-miterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_10561_361505">
        <rect
          width="16"
          height="18.62"
          fill="white"
          transform="translate(4 2)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
